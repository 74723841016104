<template>
  <div class="wrapper">
    <div class="card">
      <div class="logo">
        <img src="@/assets/ara_logo_name.png" alt="Ara Logo">
      </div>
      <h1>Unlock AI-Powered Social Insights</h1>
      <p class="tagline">Turn online chatter into smart decisions</p>

      <ul class="features">
        <li class="feature">
          <i class="fas fa-chart-line"></i>
          <span>Instant AI Reports: Get quick summaries of public views</span>
        </li>
        <li class="feature">
          <i class="fas fa-search"></i>
          <span>Spot Trends: Discover new topics and sentiments</span>
        </li>
        <li class="feature">
          <i class="fas fa-lightbulb"></i>
          <span>Smart Insights: Make informed choices with data</span>
        </li>
      </ul>

      <form @submit.prevent="handleAccess">
        <input type="text" id="accessCode" v-model="accessCode" required placeholder="Enter your access code">
        <button type="submit">Access Demo</button>
      </form>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      <p class="cta">Need an access code? <a href="mailto:kyle@araplatforms.com">Request one here</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginView",
  data() {
    return {
      accessCode: "",
      errorMessage: ""
    };
  },
  methods: {
    handleAccess() {
      const correctHash = this.hashCode("arademo");

      if (this.hashCode(this.accessCode) === correctHash) {
        localStorage.setItem("authHash", correctHash);
        this.$emit('authenticated', true);
      } else {
        this.errorMessage = "Incorrect code. Please try again.";
      }
    },
    hashCode(str) {
      let hash = 0;
      if (str.length === 0) return hash;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
      }
      return hash.toString();
    }
  }
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #e0f7fa, #fff1f9, #e8f5e9);
  padding: 20px;
  box-sizing: border-box;
}

.card {
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.logo img {
  height: 40px;
}

h1 {
  font-size: 1.6rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.tagline {
  color: #4A7A9D;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
}

.features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;
  text-align: left;
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  color: #444;
}

.feature i {
  font-size: 1.8rem;
  color: #4A7A9D;
  margin-right: 1rem;
  width: 30px;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  width: 100%;
  max-width: 300px;
  padding: 0.75rem;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
}

button {
  width: auto;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: white;
  background-color: #4A7A9D;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #3A6A8D;
}

.cta {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #666;
}

.cta a {
  color: #4A7A9D;
  text-decoration: none;
}

.cta a:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  margin-top: 1rem;
}
</style>
