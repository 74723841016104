<template>
  <div id="app">
    <LoginView v-if="!isAuthenticated" @authenticated="handleAuthentication" />
    <template v-else>
      <HeaderComponent 
        @toggle-sidebar="toggleSidebar" 
        :isSmallScreen="isSmallScreen"
      />
      <div class="main-container">
        <SidebarComponent
          ref="sidebarComponentRef" 
          class="sidebar-component"
          :class="{ hidden: !isSidebarVisible }"
          :is-sidebar-visible="isSidebarVisible"
          :isSmallScreen="isSmallScreen"
          @report-generated="handleReportGenerated"
          @report-selected="handleReportSelected"
          @close-sidebar="closeSidebar"
        />
        <TopicSearchView 
          class="topic-search-view"
          :isSidebarVisible="isSidebarVisible"
          :isSmallScreen="isSmallScreen"
          :currentReport="currentReport"
          :posts="currentPosts"
          @toggle-sidebar="toggleSidebar"
          @suggested-topic-selected="handleSuggestedTopicSelected"
          :style="{ maxWidth: isSidebarVisible ? 'calc(100% - 300px)' : '100%' }"
        />
      </div>
    </template>
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import TopicSearchView from './components/TopicSearchView.vue'
import LoginView from './components/LoginView.vue'
import SidebarComponent from './components/SidebarComponent.vue'
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    TopicSearchView,
    LoginView,
    SidebarComponent,
  },
  setup() {
    const isSmallScreen = ref(false);
    const isSidebarVisible = ref(true);
    const isAuthenticated = ref(false);
    const currentReport = ref(null);
    const currentPosts = ref([]); // Add this line
    const sidebarComponentRef = ref(null);

    const checkScreenSize = () => {
      isSmallScreen.value = window.innerWidth < 768;
      isSidebarVisible.value = !isSmallScreen.value;
    };

    onMounted(() => {
      checkScreenSize();
      window.addEventListener('resize', checkScreenSize);

      // Check if user is already authenticated by checking localStorage
      const authHash = localStorage.getItem("authHash");
      const correctHash = hashCode("arademo");

      if (authHash === correctHash) {
        isAuthenticated.value = true;
      }
    });

    onUnmounted(() => {
      window.removeEventListener('resize', checkScreenSize);
    });

    const toggleSidebar = () => {
      console.log('App: toggleSidebar called, current state:', isSidebarVisible.value);
      isSidebarVisible.value = !isSidebarVisible.value;
      console.log('App: New state:', isSidebarVisible.value);
    };

    const closeSidebar = () => {
      isSidebarVisible.value = false;
    };

    const handleAuthentication = (authenticated) => {
      isAuthenticated.value = authenticated;
    };

    const handleReportGenerated = (data) => {
      currentReport.value = data.report;
      currentPosts.value = data.posts; // Store posts data
    };

    const handleReportSelected = (report) => {
      currentReport.value = report;
    };

    const handleSuggestedTopicSelected = (topic) => {
      // Emit the event to the SidebarComponent
      sidebarComponentRef.value?.handleSuggestedTopic(topic);
    };

    const hashCode = (str) => {
      let hash = 0;
      if (str.length === 0) return hash;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
      }
      return hash.toString();
    };

    return {
      isSmallScreen,
      isSidebarVisible,
      isAuthenticated,
      currentReport,
      currentPosts, // Add this line
      toggleSidebar,
      closeSidebar,
      handleAuthentication,
      handleReportGenerated,
      handleReportSelected,
      handleSuggestedTopicSelected,
      sidebarComponentRef,
    };
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css');

body,
html {
  font-family: 'Nunito Sans', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #333;
  background-color: #f9f9f9;
  font-size: 12px; /* Reduced global font size */
}

.main-container {
  display: flex;
  flex: 1;
  overflow: hidden;
  height: calc(100vh - 60px);
}

.sidebar-component {
  width: 300px;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}

.sidebar-component.hidden {
  transform: translateX(-300px);
  width: 0;
}

.topic-search-view {
  flex-grow: 1;
  transition: margin-left 0.3s ease-in-out;
  margin-left: 0;
}

.topic-search-view.expanded {
  margin-left: -300px;
}

button {
  background-color: #4A7A9D;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #3d6380;
}
</style>
