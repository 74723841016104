<template>
    <header>
        <button class="menu-btn" @click="emitToggleSidebar">
            <i class="fas fa-bars"></i>
        </button>
        <nav>
            <!-- ... other nav items ... -->
        </nav>
        <img src="@/assets/portrait.png" alt="Avatar" class="avatar">
    </header>
</template>

<script>
export default {
    name: 'HeaderComponent',
    props: {
        isSmallScreen: Boolean,
    },
    emits: ['toggle-sidebar'],
    methods: {
        emitToggleSidebar() {
            console.log('HeaderComponent: Emitting toggle-sidebar event');
            this.$emit('toggle-sidebar');
        }
    }
}
</script>

<style scoped>
header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Added to space items */
    padding: 10px 20px;
    background-color: #ffffff; /* White background */
    border-bottom: 1px solid #e0e0e0; /* Border color */
}

.menu-btn {
    background: none;
    border: none;
    font-size: 24px;
    color: #0066CC; /* Primary color */
    cursor: pointer;
    margin-right: 15px;
    transition: color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
}

.menu-btn:hover {
    color: #005cb3; /* Slightly lighter blue on hover */
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Makes the image circular */
    margin-left: auto; /* Ensures the avatar is right-aligned */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .menu-btn {
        font-size: 20px;
    }
}
</style>
